
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted } from 'vue';

// icons
import { add, close, checkmark, arrowUp,  arrowForward, arrowBack, openOutline, trashOutline, } from 'ionicons/icons';

// components
import { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonGrid, IonRow, IonCol,
        IonList, IonItem, IonLabel, IonIcon, IonButtons, IonButton,
        IonSearchbar, IonSegment, IonSegmentButton, IonSpinner, } from '@ionic/vue';
import SectorModal from '@/components/pss/SectorModal.vue';

// composables / services
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { useStore } from '@/store';

export default defineComponent({
  name: 'ListSectorModal',
  props: ["majorRelatedSectors"],
  components: { IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonGrid, IonRow, IonCol,
                IonList, IonItem, IonLabel, IonIcon, IonButtons, IonButton,
                IonSearchbar, IonSegment, IonSegmentButton, IonSpinner, },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { openModal, closeModal, infiniteScrollLoadData, } = utils();
    const { t } = useI18n();

    const loading = computed(() => store.state.loadingData);
    const allSectors = computed(() => store.state.allSectors);
    const openSectorModal = async (sector: any) => {
      return await openModal(SectorModal, { sectorId: sector.id })
    }

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, openOutline, trashOutline,

      // variables
      loading, allSectors,

      // methods
      t,
      closeModal,
      openSectorModal,
      filteredSectors: () => {
        const { majorRelatedSectors } = props;
        if (majorRelatedSectors && majorRelatedSectors.length > 0) {
          return allSectors.value.filter(s => !majorRelatedSectors.find(rs => rs.id == s.id));
        }
        return allSectors.value;
      },
      existMajorRelatedSectors: () => (props.majorRelatedSectors && props.majorRelatedSectors.length > 0),
    }
  }
});
