
// Vue reactivity
import { ref, defineComponent, computed, watch, reactive, onMounted, onUnmounted, onBeforeUnmount } from 'vue';

// icons
import { add, close, checkmark, arrowUp, arrowForward, arrowBack, trashOutline,
        thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
        chevronBack, chevronForward, repeat, search, createOutline, pencil, ellipsisVertical, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonGrid, IonRow, IonCol, IonSpinner,
        IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonSearchbar, IonSegment, IonSegmentButton, IonList,
        IonAvatar, IonCard, IonCardContent, IonTextarea,
        IonChip, IonText, IonCheckbox, IonRippleEffect, IonFab, IonFabButton, IonInfiniteScroll, IonInfiniteScrollContent,
        IonicSlides, isPlatform, modalController, } from '@ionic/vue';
import ProfessionModal from '@/components/pss/ProfessionModal.vue';
import ListSegmentModal from '@/components/university/ListSegmentModal.vue';
import ListSectorModal from '@/components/university/ListSectorModal.vue';
import ChatbotModal from '@/components/modals/ChatbotModal.vue';

// Swiper
import 'swiper/swiper.min.css';
import 'swiper/modules/effect-cards/effect-cards.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import '@ionic/vue/css/ionic-swiper.css';
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue';
import { EffectCards, Navigation, } from 'swiper';

// composables
import { useI18n } from 'vue-i18n';
import { utils } from '@/composables/utils';
import { utilsGPT } from '@/composables/utilsGPT';
import { useStore } from '@/store';
import { useRoute, useRouter } from 'vue-router';

// types
import { Profession, ProfessionTab, Step1Option, Step1Question, UserClaim, UserProfession, } from '@/types';

// services
import ABSService from '@/services/ABSService';

// lib
import config from '@/config';

export default defineComponent({
  name: 'UniProfessionDeckModal',
  props: ["prefilledProfessions", "oldUserProfessions", "isYear1", "isSecSchoolStudents", "isPage"],
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonFooter, IonGrid, IonRow, IonCol, IonSpinner,
                IonItem, IonLabel, IonIcon, IonButtons, IonButton, IonSearchbar, IonSegment, IonSegmentButton, IonList,
                IonAvatar, IonCard, IonCardContent, IonTextarea,
                IonChip, IonText, IonCheckbox, IonRippleEffect, IonFab, IonFabButton, IonInfiniteScroll, IonInfiniteScrollContent,
                Swiper, SwiperSlide },
  setup(props) {
    // methods or filters
    const store = useStore();
    const { t } = useI18n();
    const router = useRouter();
    const { openModal, closeModal, doReorder, infiniteScrollLoadData,
          processUserItems, isItemSelected, isItemDisliked, isMobileWeb,
          onThumbsUpItem, onThumbsDownItem, onClickMoreBtn, onClickPrevBtn, animateToFirstCard,
          recordCurrSlideReaction, resetActiveSlide, syncChosenItems, resetFilters, focusKeywordSearchbar,
          presentAlert, } = utils();

    const mode = ref("default"); // default / gpt (AI-assisted)
    const user = computed(() => store.state.user);
    const userRelatedJobEX = computed(() => store.getters.userRelatedJobEX);
    const loadingData = computed(() => store.state.loadingProgram);
    const programWithProfessions = computed(() => store.state.currProgram);
    const questions = computed<Step1Question[]>(() => store.getters.getUserRelatedStep1Questions(props.isYear1));

    const passionTags = computed<Step1Option[]>(() => store.getters.getStep1OptionsByQuestionId("q-192282d2"));
    const step1Questions = computed(() => store.getters.getUserRelatedStep1Questions(props.isYear1, true));
    const step1OptionIds = computed(() => store.getters.getUserRelatedStep1OptionIds(props.isYear1));

    const selectedItems = ref<Profession[]>(props.prefilledProfessions || []);
    const userItems = ref<UserProfession[]>(props.oldUserProfessions || []);
    const tmpNewUserItems = ref<UserProfession[]>([]); // for storing slideChange user professions
    const allProfessions = ref<Profession[]>(store.getters.shuffledProfessions(false));
    //const allProfessions = ref<Profession[]>(store.state.allProfessions);
    const allProfessionTabs = computed<ProfessionTab[]>(() => store.state.professionTabs);
    const LEV_DOMAIN_TAB_ID = 298;
    const SUP_FUNC_TAB_ID = 299;

    const selectedFilterGroup = ref('');
    const selectedOption = ref<any>("all");

    const searchKeyword = ref('');
    const isSearching = ref(false);
    const delayLoading = ref(true);

    /**
     * GPT (strength-based recommendations)
     */
    const { tagObjOther, parseMsg, fetchGPTResponse, parseGPTResponse, whatsAppSendGPTResults, upsertUserItems, getStrengthDescription, initGPTObj, } = utilsGPT();
    const gpt = reactive({
      selectedTagGroup: "",
      userInputText: "", // user input prompt
      tagId: "",
      tagText: "",
      currStep: 1,
      waitingGPTResp: false,
      isInCooldownTime: false, // prevent users sending requests consecutively
      cooldownSecLeft: 0,
    });
    const botSuggestedProfessions = ref<Profession[]>([]);
    const currViewingProfession = ref<Profession>();
    const getRecommendedProfessionsFromGPT = async () => {
      try {
        botSuggestedProfessions.value = []; // reset
        currViewingProfession.value = undefined; // reset
        const MAX_PROFESSIONS = 10, clientId = userRelatedJobEX.value["Client"];
        let overrideBotName = config.separatePoeBotClientIds.includes(clientId) ? clientId : ""; // separate Poe bots
        if (userRelatedJobEX.value.relatedProgramIds.includes('226')) overrideBotName = 'LU_MIBF'; // TMP: use MIBF professions

        let prompt = `Key strength: ${getStrengthDescription(gpt)}`;
        prompt += `\n\nAbove is the information about a Hong Kong university student (${userRelatedJobEX.value.relatedProgramNames[0]}).`
        prompt += ` Please suggest at most ${MAX_PROFESSIONS} unique professions that are most relevant to the student based on his/her strength. `;
        prompt += `The professions must ONLY be those related to the client "${clientId}".`;
        prompt += ` For each profession, please explain in detail how the student's key strength is applied in most related daily tasks in English to convince the student that the profession is suitable. Be as specific & concrete as possible.`;
        prompt += `\n\nYour response MUST be formatted in JSON with only an array of JavaScript objects (at most ${MAX_PROFESSIONS} objects), each object must contain exactly 2 fields: "id", "explanation"\n`;
        prompt += `Example response: [{"id":"499","explanation":"XXX"}]`;

        // Send request (TBC: separate bot URL for university? May not be needed given the override bot)
        const data = await fetchGPTResponse(gpt, "https://ab-chatgpt-api.fdmt.hk/slp", prompt, user.value.id, overrideBotName);
        if (!data) throw new Error("No data");

        // Parse data (bot responses)
        await parseGPTResponse(gpt, data, currViewingProfession, botSuggestedProfessions, allProfessions, '#card-swiper-slides', 'professionId');

        // Insert to DB for records first
        upsertUserItems(user.value.id, botSuggestedProfessions, userItems, 'professionId');

        // Send out the results to student's WhatsApp group for records
        whatsAppSendGPTResults(user.value.phone, user.value.waGroupId, getStrengthDescription(gpt), botSuggestedProfessions, 'profession');
      } catch (e) {
        console.error(e);
        presentAlert("ChatGPT did not response. Please try again");
        gpt.currStep = 1;
      } finally {
        gpt.waitingGPTResp = false;
      }
    }

    const numOfVisibleItems = ref(20);
    const loadData = (ev: any) => { infiniteScrollLoadData(ev, numOfVisibleItems, store.state.allProfessions) }

    const getAppState = () => ({
      selectedFilterGroup: selectedFilterGroup.value,
      selectedOption: selectedOption.value,
      searchKeyword: searchKeyword.value,
    });
    const confirmSelect = async (noLoading = false) => {
      if (props.isPage) router.replace('/home');
      else {
        await closeModal({
          "selectedProfessions": selectedItems.value, noLoading,
          "userProfessions": processUserItems(selectedItems.value, userItems.value, tmpNewUserItems.value, 'professionId', store.state.user.id),
        }); // return selected items & order here
      }
    };
    const openProfessionModal = async (professionId: any, gptExplanationHTML = "") => {
      // Check suppliers serve the sector (apply domain knowledge in generic professions)
      const overrideSegments = [];
      const relatedSectors = [SUP_FUNC_TAB_ID].includes(selectedOption.value.id) ? programWithProfessions.value['related_sectors'] : [];
      await openModal(ProfessionModal, { gptExplanationHTML, professionId, useBackButton: true, relatedSectors, overrideSegments, expandAlumniSection: selectedFilterGroup.value == 'alumni' });
    };

    // Helper functions for card slides
    const recordActiveSlideReaction = () => {
      recordCurrSlideReaction('professionId', userItems, tmpNewUserItems, getAppState());
    }
    const isSelected = (item: any) => (isItemSelected(item, selectedItems));
    const isDisliked = (item: any) => (isItemDisliked(item, 'professionId', userItems));

    const startIdx = ref(0); // for card slides

    // Event Cycle
    onMounted(() => {
      selectedFilterGroup.value = questions.value[0]?.id;

      // Prefill with previously written reasons
      syncChosenItems('professionId', selectedItems, userItems, allProfessions.value);

      setTimeout(() => {
        delayLoading.value = false;
        setTimeout(() => {
          const slides: any = document.querySelector('#card-swiper-slides');
          if (slides) {
            slides.swiper.on('slideChange', recordActiveSlideReaction);
            recordActiveSlideReaction(); // initial slide
          }
        }, 200);
      }, 200);

      // AI: Prefill previously input text & selected tag
      initGPTObj(gpt, user.value.claims);
    })
    watch(selectedOption, () => {
      resetActiveSlide(startIdx, delayLoading, 'professionId', userItems, tmpNewUserItems, getAppState());
    })
    watch(selectedFilterGroup, (currGroup) => {
      if (currGroup) {
        if (['like', 'dislike'].includes(currGroup)) {
          selectedOption.value = currGroup;
        }
        else if (currGroup == 'random') {
          resetFilters(selectedFilterGroup, selectedOption);
          allProfessions.value = store.getters.shuffledProfessions(false);
          animateToFirstCard();
        }
        else if (currGroup == 'search') {
          //selectedOption.value = 'all';
          resetFilters(selectedFilterGroup, selectedOption);
          focusKeywordSearchbar(isSearching);
        }
        else if (currGroup == 'sectors') {
          resetFilters(selectedFilterGroup, selectedOption);
          openModal(ListSectorModal, { majorRelatedSectors: programWithProfessions.value['related_sectors'] });
        }
        else if (currGroup == "video-taking") { // Video taking (demo professions)
          selectedOption.value = store.getters.getStep1OptionById("o-fdda21f6");
        }
        else {
          const q = questions.value.find(q => q.id == currGroup) || { options: [] };
          selectedOption.value = q.options[0] || {};
        }
      }
    })
    watch(loadingData, () => {
      allProfessions.value = store.getters.shuffledProfessions(false);
    })
    watch(questions, () => {
      setTimeout(() => {
        selectedFilterGroup.value = questions.value[0]?.id;
      }, 500);
    })
  
    const getAlumniProfessions = () => {
      return (programWithProfessions.value.alumniContactRelations || []).filter(cr => cr.entityType == 'profession').map(cr => {
        return allProfessions.value.find(p => p.id == cr.entityId) || {};
      });
    }

    // return variables & methods to be used in template HTML
    return {
      // icons
      add, close, checkmark, arrowUp, arrowForward, arrowBack, trashOutline,
      thumbsUpOutline, thumbsDownOutline, thumbsUp, thumbsDown, heart, heartOutline,
      createOutline, pencil, ellipsisVertical,

      // variables
      user, userRelatedJobEX,
      questions,
      selectedOption,
      selectedItems, userItems, tmpNewUserItems,
      searchKeyword, isSearching,
      delayLoading,

      // methods
      t, isMobileWeb,
      doReorder,
      confirmSelect, closeModal, openProfessionModal,
      onSearchKeywordUpdated: () => {
        ABSService.insertProfessionSearchKeywordRecord(searchKeyword.value);
      },
      recommendedProfessions: () => {
        // Alumni Professions
        if (selectedFilterGroup.value == "alumni") return getAlumniProfessions();

        // Others
        const selectedOpt = selectedOption.value;
        if (searchKeyword.value) {
          const filteredProfessions = searchKeyword.value == '' ? allProfessions.value : allProfessions.value.filter(p => {
            return p.name.toLowerCase().includes(searchKeyword.value.toLowerCase()) || (p.nameChinese && p.nameChinese.includes(searchKeyword.value));
          });
          return filteredProfessions;
        }
        if (['like', 'dislike'].includes(selectedOpt)) {
          return userItems.value.filter(up => up.reaction == selectedOpt).map(up => {
            return allProfessions.value.find(p => p.id == up.professionId); 
          });
        }
        let filteredProfessions: any = allProfessions.value; // Default shuffle all professions (every time enter)
        if (selectedOpt && selectedOpt.id) {
          // Step 1 Question & Option Mapping
          const { tabIds, professionIds } = selectedOpt;
          let tabProfessionIds = [];
          for (const tid of tabIds) {
            const pTab = allProfessionTabs.value.find(pt => pt.id == tid);
            if (pTab) tabProfessionIds = tabProfessionIds.concat(pTab.relatedProfessionIds);
          }
          const relatedProfessionIds = professionIds.length > 0 ? professionIds : tabProfessionIds; // override
          filteredProfessions = [
            ...relatedProfessionIds.map(id => (allProfessions.value.find((p: Profession) => p.id == id.toString()))),
          ].filter(p => p != null);
        }
        else {
          // sort by order in profession tabs
          const sortOrders = {};
          let countTab = 0;
          for (const q of questions.value) {
            for (const opt of q.options) {
              const { tabIds, professionIds } = opt;
              let tabProfessionIds = [];
              for (const tid of tabIds) {
                const pTab = allProfessionTabs.value.find(pt => pt.id == tid);
                if (pTab) tabProfessionIds = tabProfessionIds.concat(pTab.relatedProfessionIds);
              }
              const relatedProfessionIds = professionIds.length > 0 ? professionIds : tabProfessionIds; // override
              for (let i = 0; i < relatedProfessionIds.length; i++) {
                const pid = relatedProfessionIds[i];
                sortOrders[pid] = sortOrders[pid] || Infinity;
                sortOrders[pid] = Math.min(sortOrders[pid], i) + countTab;
              }
              countTab++;
            }
          }
          filteredProfessions.sort((a, b) => {
            const orderA = a.id in sortOrders ? sortOrders[a.id] : Infinity;
            const orderB = b.id in sortOrders ? sortOrders[b.id] : Infinity;
            return orderA-orderB;
          });
        }
        filteredProfessions = [
          ...filteredProfessions.filter(d => !isDisliked(d)),
          ...filteredProfessions.filter(d => isDisliked(d)),
        ];
        if (props.isYear1 || selectedOpt?.professionTabId == LEV_DOMAIN_TAB_ID) { // Leverage domain knowledge (generic professions)
          return filteredProfessions;
        }
        if (selectedFilterGroup.value == "video-taking") return filteredProfessions; // no need filter related professions

        //return filteredProfessions;
        const relatedProfessionIds = programWithProfessions.value.allRelatedProfessions.map(p => p.id);
        return relatedProfessionIds.map(id => filteredProfessions.find(p => p.id == id)).filter(p => !!p); // maintain the order
        //return filteredProfessions.filter(p => relatedProfessionIds.includes(p.id));
      },

      onThumbsUp: (item: any, flipToNextSlide = true) => (onThumbsUpItem(item, 'professionId', selectedItems, userItems, tmpNewUserItems, getAppState(), flipToNextSlide, true)),
      onThumbsDown: (item: any) => (onThumbsDownItem(item, 'professionId', selectedItems, userItems, tmpNewUserItems, getAppState())),
      isSelected, isDisliked,

      // swiper
      modules: [EffectCards, IonicSlides, Navigation],
      startIdx,
      onClickMoreBtn: () => { onClickMoreBtn(startIdx) },
      onClickPrevBtn: () => { onClickPrevBtn(startIdx) },

      // Filter groups & filters
      chevronBack, chevronForward, repeat, search,
      selectedFilterGroup,

      getAppStateText: (itemId: any) => {
        const up = userItems.value.find(up => (up.professionId == itemId));
        const { selectedOption, searchKeyword } = up?.appState || {};
        return searchKeyword || (selectedOption ? selectedOption.text : null);
      },
      
      onCheckProfession: (checked: any, profession: Profession) => {
        if (checked) {
          if (selectedItems.value.find(p => p.id == profession.id) == null) {
            selectedItems.value.unshift(profession);
          }
        }
        else {
          const idx = selectedItems.value.findIndex(p => p.id == profession.id);
          if (idx !== -1) selectedItems.value.splice(idx, 1);
        }
      },

      // Infinite scroll
      numOfVisibleItems, loadData,
      
      // Other
      loadingData,
      openListSegmentModal: async (profession) => (openModal(ListSegmentModal, { profession })),

      // Alumni corner
      getAlumniProfessions,

      // GPT
      passionTags, step1Questions, step1OptionIds,
      getCourseOptions: () => (step1Questions.value['Course'] || []),

      gpt, botSuggestedProfessions, currViewingProfession,
      getRecommendedProfessionsFromGPT, parseMsg,
      tagObjOther, toggleSelectedTag: (tagId, tagText) => { gpt.tagId = tagId; gpt.tagText = tagText },

      openChatbotModal: async (profession: any) => {
        const { name, nameChinese, explanation } = profession;
        const professionName = `${name} ${nameChinese}`;
        const botExplanation = `${professionName}\n${explanation}`;
        const prefilledPrompt = `Please elaborate more about how my key strength is applied in ${professionName}: ${getStrengthDescription(gpt)}`;
        return await openModal(ChatbotModal, { isFromAB4: true, professionName, botExplanation, professionId: profession.id, prefilledPrompt });
      },
    }
  },
});
